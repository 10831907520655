<!--
 * @Author: 张博洋
 * @Date: 2021-08-20 20:52:01
 * @LastEditTime: 2021-09-18 16:21:53
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /hospital-h5/src/views/im/prescroption.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="wrapper">
    <notice-bar :noticeIconColor="noticeOption.iconColor" :noticeIcon="noticeOption.icon" :noticeBackground="noticeOption.background" v-if="prescriptionDetail.rpExpired === 1" class="mb8">
      <span class="str" style="color:#333">{{noticeOption.str}}</span>
    </notice-bar>
    <div class="mt8" style="overflow-y:auto; flex:1">

      <div class="p head">
        <h1 class="">天津易赋诊互联网医院</h1>
        <h2 class="mt8">电子处方笺</h2>
        <img src="../../static/img/line.png" alt="">
        <div class="con borderb mt24 line-height">
          <p>编号： <span>{{prescriptionDetail.orderNo}}</span></p>
          <p>费别：<span>自费</span></p>
        </div>
        <div class="con mt12">
          <p>姓名： <span>{{prescriptionDetail.patientName}}</span></p>
          <p>性别： <span>{{prescriptionDetail.patientSex === 1 ? '男' : '女'}}</span></p>
          <p>年龄： <span>{{prescriptionDetail.patientAge}}</span></p>
        </div>
        <div class="con mt12">
          <p>开方日期： <span>{{$.replyTime(prescriptionDetail.createTime)}}</span></p>
        </div>

        <div class="btn-check" @click="toPdf">原始处方</div>
      </div>

      <div class="hsitory p mt8">
        <h1>诊断</h1>
        <div class="tags">
          <div class="tag" v-for="(d,index) in prescriptionDetail.diagnosisIcdList" :key="index">{{d.name}}</div>
        </div>
      </div>

      <div class="rp mt8 mb8">
        <h1>RP</h1>
        <div class="rp-item mb8" v-for="(item,index) in prescriptionDetail.list" :key="index">
          <div class="head">
            <span class="name">{{item.drugName}}</span>
            <span class="num">x{{item.drugAmount}}</span>
          </div>
          <div class="con mt13">
            <span>规格：{{item.specification}} </span>
            <span>每次剂量：{{item.perDosage}} {{item.useUnit}}</span>
            <span>频次：{{item.frequency}}</span>
            <span>服药方法：{{item.drugUsage}}</span>
          </div>
        </div>
      </div>

      <!-- <div class="sign p">
        <div>
          医师：{{prescriptionDetail.doctorName}}
        </div>
        <div>
          药师：{{prescriptionDetail.pharmacistName}}
        </div>
      </div> -->

      <div class="p mt8">
        <van-cell title="科室" :value="prescriptionDetail.doctorDeptName" />
        <van-cell title="医师" :value="prescriptionDetail.doctorName" />
        <van-cell title="药师" :value="prescriptionDetail.pharmacistName" />
      </div>

      <div class="tip mt20 mb8">
        <p>特别提示</p>
        <p>1、处方有效期{{prescriptionDetail.prescriptionExpire}}天 </p>
        <p>2、本处方限于天津易赋诊互联网医院使用，自行下载配药不具备处方效力。</p>
        <p>3、按照卫生部、国家中医药管理局卫医发[2002]24号文件规定：为保证患者用药安全，药品一经发出，不得退换。</p>
      </div>
    </div>

    <div class="btn-group mt8" v-if="prescriptionDetail.rpExpired === 0">
      <van-button class="buydrug" @click="buydrug" :loading="btnLoading" round>立即购药</van-button>

    </div>
  </div>

</template>

<script>
import NoticeBar from '@/components/noticeBar'
export default {
  data() {
    return {
      pageName: '',
      prescriptionDetail: Object.create(null),
      pdf: '',
      png: '',
      noticeOption: {
        iconColor: '#ED6A0C',
        icon: 'info-o',
        background: '#FFFBE8',
        str: '处方已失效',
      },
    }
  },
  components: {
    NoticeBar,
  },
  created() {
    this.getPrescriptionOrder()
  },
  methods: {
    getPrescriptionOrder() {
      this.$axios({
        url: 'order/getPrescriptionOrder',
        data: {
          id: this.$route.query.rpId,
        },
        elseData: {
          loading: true,
          ajaxLoading: true,
          errorTip: true,
        },
        _self: this,
      }).then((res) => {
        console.log(res)
        this.prescriptionDetail = res.d
        this.png = this.prescriptionDetail.pdf.replace('pdf', 'png')
        console.log(this.png)
      })
    },
    buydrug() {
      this.$router.push({
        name: 'drug.detail',
        query: {
          id: this.prescriptionDetail.id,
          state: this.prescriptionDetail.state,
          prescriptionId: this.prescriptionDetail.prescriptionId,
        },
      })
      //   wx.miniProgram.redirectTo({
      //     url: `/pages/protocol/protocol?src=${this.prescriptionDetail.pdf}`
      //   });
    },
    toPdf() {
      this.$router.push({
        name: 'pdf.pdf',
        query: {
          src: this.prescriptionDetail.pdf,
        },
      })
      // wx.miniProgram.navigateTo({
      //     url: `/pages/protocol/protocol?src=${this.prescriptionDetail.pdf}`
      // });
      this.$.setTitle('原始处方')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/variables.scss';
.position {
  position: relative;
  z-index: 99;
}
.wrapper {
  display: flex;
  flex-direction: column;
}

.p {
  margin: 0 8px;
  background: #ffffff;
  border-radius: 8px;
  padding: 8px 16px;
  .van-cell {
    padding-left: 0;
    padding-right: 0;
    .van-cell__title {
      color: #666;
      flex: none;
    }
    .van-cell__value {
      word-wrap: unset;
      color: #333;
      margin-left: 10px;
    }
  }
  .van-cell::after {
    left: 0;
    right: 0;
  }
}
.head {
  position: relative;
  padding-top: 31px;
  .btn-check {
    position: absolute;
    right: 8px;
    top: 10px;
    border-radius: 12px;
    border: 1px solid #e5e5e5;
    font-size: 12px;
    text-align: center;
    padding: 3px 8px;
  }
  h1 {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    color: #333333;
  }
  h2 {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
  }
  & > img {
    width: 100%;
  }
  .con {
    display: flex;
    justify-content: space-between;
    p {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      span {
        font-weight: 400;
        color: #333333;
      }
    }
  }
  .borderb {
    border-bottom: 1px solid #f5f5f5;
  }
  .line-height {
    line-height: 48px;
  }
}
.hsitory {
  padding-bottom: 16px;
  h1 {
    height: 49px;
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    line-height: 49px;
  }
  .tags {
    display: flex;
  }
  .tag {
    background: #f5f5f5;
    border-radius: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 6px 13px;
    margin: 6px 14px 0 0;
    min-width: 70px;
    text-align: center;
    max-width: 150px;
    font-size: 14px;
    color: #333333;
  }
}
.rp {
  h1 {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    line-height: 50px;
    padding: 0 20px;
  }
  .rp-item {
    background: #ffffff;
    border-radius: 8px;
    margin: 0 8px;
    padding: 20px 16px;
    .head {
      display: flex;
      justify-content: space-between;
      .name {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
        display: inline-block;
      }
      .num {
        padding: 0 8px;
        line-height: 28px;
        border-radius: 4px;
        border: 1px solid #e5e5e5;
        display: inline-block;
        font-size: 16px;
        text-align: center;
        font-weight: 400;
        color: #333333;
      }
    }
    .con {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      span {
        width: 40%;
        display: inline-block;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
      }
    }
  }
}
.sign {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  padding: 15px 16px;
}
.tip {
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  line-height: 21px;
  padding: 0 24px;
}

.btn-group {
  // position: fixed;
  // bottom: 0;
  width: 100%;
  display: flex;
  padding: 10px 16px 30px 16px;
  background: #ffffff;
  .buydrug {
    width: 100%;
    flex: 1;
    background: color(primary) !important;
    color: #fff;
  }
}
</style>